<template>
  <div>

    <b-container fluid
                 class="part1">
      <b-row>
        <b-col class="col-12 text2 text-center text-lg-left">门窗行业产品解决方案</b-col>
        <b-col class="col-12 text3 text-center text-lg-left">提供销售人员线上获客、产品展示、案例展示、预约到店、谈单签约的软件功能，为测量安装人员提供测量、现场还原的软件功能，解决测量“0”出错、“0”遗漏、“0”重复沟通、“0”重复跑现场、人不等时间的痛点，经销商一键下单到工厂，并实现工厂到经销商到业主紧密协作，质保项目不出错。</b-col>
        <b-col class="col-12 text-center text-lg-left">
          <b-button pill
                    variant="outline-light"
                    class="mr-3"
                    @click="show">查看演示</b-button>
          <b-button pill
                    class=""
                    @click="toTry"
                    variant="primary">免费试用</b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid
                 class="part2">
      <div class="part2_title text-center">行业痛点</div>
      <div class="part2_content">门窗行业是个万亿体量的大生意，却极难标准化，对于大部分想把生意做大的经销商而言有着极高的门槛，在门店签单后，开始上门精准量尺，跟业主沟通要做什么样的产品，以及产品具备的功能、安全性、场景作用、美观度，除了跟业主沟通外业务员更要考虑产品安装时怎么入户、用什么样的安装工艺以及可能存在的安装风险等，那么对业务人员的要求，不仅业务能力要强，还要懂设计懂安装，因为在量尺沟通时只要发生错误就会影响交付，甚至影响制作出来的门窗报废，同时在沟通过程中，还存在业主对产品没概念说不出来需求，今天确定的内容过几天就要改方案。这仅仅是经销商对接业主这一端，经销商还需要跟生产厂商、材料商、施工人员、物业等多方对接，同时门窗需要多次服务才能收尾，期间还会出现换人，易出现沟通出错等问题，且回款周期也长，这更加的提高了门窗服务的难度。所以只要保证门窗的服务不出错，带给业主快乐的服务体验，那么想将门窗生意做大，则变得相当容易。
      </div>
      <div class="hard">影响门窗服务的“10”难</div>
      <div class="hard_content">
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon1.png')"></b-img>
          <p>获客难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon2.png')"></b-img>
          <p>签单难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon3.png')"></b-img>
          <p>测量难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon4.png')"></b-img>
          <p>找人难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon5.png')"></b-img>
          <p>沟通难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon6.png')"></b-img>
          <p>入户难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon7.png')"></b-img>
          <p>安装难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon8.png')"></b-img>
          <p>交付难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon9.png')"></b-img>
          <p>回款难</p>
        </div>
        <div class="item">
          <b-img :src="require('../../assets/img/solution/icon10.png')"></b-img>
          <p>售后难</p>
        </div>
      </div>
    </b-container>
    <b-container fluid
                 class=" part3">
      <div class="part3_title text-center">产品解决方案</div>
      <div class="part3_content">
        测量大师以测量为切入点，助力门店提升更专业的深度服务，寻找更大的业务增长空间，从产品曝光到触达终端客户、到获客签单、测量沟通、下单生产、产品运输、入户安装、交付回款、以及最后的售后服务，同时支持对接各类ERP、微商城等第三方软件服务，全力帮扶门店长业绩。
      </div>
      <div class="content">

        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/solution/item1.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible1 ? null : 'collapsed'"
                 :aria-expanded="visible1 ? 'true' : 'false'"
                 aria-controls="collapse-1"
                 @click="isPhone?visible1 = !visible1:null">
              <div class="item_title">获客签单</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>

            <b-collapse v-model="visible1"
                        id="collapse-1">
              <div class="item_content">当门店交付项目后，系统自动化生成营销方案书，适合门店线下推广、展示案例或在微信中传播，投放后，自动收集目标客户信息，引导客户到店了解，电话咨询、加微信等多种方式。</div>
              <b-button pill
                        class=""
                        @click="toTry"
                        variant="primary">免费试用</b-button>
            </b-collapse>

          </div>
        </div>
        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/solution/item2.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible2 ? null : 'collapsed'"
                 :aria-expanded="visible2 ? 'true' : 'false'"
                 aria-controls="collapse-2"
                 @click="isPhone?visible2 = !visible2:null">
              <div class="item_title">测量沟通</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible2"
                        id="collapse-2">
              <div class="item_content">使用测量大师APP用来还原现场记录测量结果，测量大师工具箱完成现场的测量，同时支持将手写本中的内容实时自动回传到测量大师APP，保证测量沟通的内容为最原始的数据。</div>
              <b-button pill
                        class=""
                        @click="toTry"
                        variant="primary">免费试用</b-button>
            </b-collapse>

          </div>
        </div>
        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/solution/item3.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible3 ? null : 'collapsed'"
                 :aria-expanded="visible3 ? 'true' : 'false'"
                 aria-controls="collapse-3"
                 @click="isPhone?visible3 = !visible3:null">
              <div class="item_title">设计报价</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible3"
                        id="collapse-3">
              <div class="item_content">在手机中一分钟画门窗，各种门窗通过拖拉就能出效果，将门窗效果搭配到现场，并支持现场报价，支持对报价结果打折、抹零等优惠方式，促成现场签单。</div>
              <b-button pill
                        class=""
                        @click="toTry"
                        variant="primary">免费试用</b-button>
            </b-collapse>
          </div>
        </div>
        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/solution/item4.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible4 ? null : 'collapsed'"
                 :aria-expanded="visible4 ? 'true' : 'false'"
                 aria-controls="collapse-4"
                 @click="isPhone?visible4 = !visible4:null">
              <div class="item_title">项目管理</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible4"
                        id="collapse-4">
              <div class="item_content">待测量、待安装、待协调、待验收、关心的项目分类，超期项目预警、项目任务管理等，项目动态实时更新，智能提醒，项目数据永久存储，不怕丢失。</div>
              <b-button pill
                        class=""
                        @click="toTry"
                        variant="primary">免费试用</b-button>
            </b-collapse>
          </div>
        </div>
        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/solution/item5.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible5 ? null : 'collapsed'"
                 :aria-expanded="visible5 ? 'true' : 'false'"
                 aria-controls="collapse-5"
                 @click="isPhone?visible5 = !visible5:null">
              <div class="item_title">下单生产</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible5"
                        id="collapse-5">
              <div class="item_content">支持将现场测量的结果、现场状况、现场沟通的结果一键推送到工厂，与CC生产软件无缝对接，解决门店与工厂重复沟通，加速成品出货时间。</div>
              <b-button pill
                        class=""
                        @click="toTry"
                        variant="primary">免费试用</b-button>
            </b-collapse>
          </div>
        </div>
        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/solution/item6.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible6 ? null : 'collapsed'"
                 :aria-expanded="visible6 ? 'true' : 'false'"
                 aria-controls="collapse-6"
                 @click="isPhone?visible6 = !visible6:null">
              <div class="item_title">测量安装外包</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible6"
                        id="collapse-6">
              <div class="item_content">测量大师提供专业、服务性强的测量安装人员，解决门店远在异地的客户服务难的问题，帮扶门店跑通网络直播销售、淘宝、京东上卖门窗没法服务的问题。</div>
              <b-button pill
                        class=""
                        variant="outline-secondary">敬请期待</b-button>
            </b-collapse>
          </div>
        </div>
      </div>
    </b-container>
    <b-container fluid
                 class="part4">
      <div class="part4_title text-center">方案架构</div>
      <b-img class="img"
             :src="require('../../assets/img/solution/pic.png')"></b-img>
      <b-img class="img1"
             :src="require('../../assets/img/solution/pic1.png')"></b-img>
    </b-container>
    <b-container fluid
                 class="part6">
      <div class="part6_title text-center">我们的客户</div>
      <div class="brand">
        <b-img :src="require('../../assets/img/logo/logo (1).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (2).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (3).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (4).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (5).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (6).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (7).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (8).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (9).png')"></b-img>
        <b-img :src="require('../../assets/img/logo/logo (10).png')"></b-img>
      </div>
      <b-button pill
                variant="primary">
        <div class="btn_text1"
             @click="toTry">免费试用</div>
        <div class="btn_text2">{{num}}人已试用</div>
      </b-button>
    </b-container>

    <foot></foot>
    <right></right>

    <myhead :background="'transparent'"></myhead>
    <b-modal id="play"
             hide-footer
             title="">
      <player ref="play"
              :video-url="videoUrl"
              :state="state"></player>

    </b-modal>

  </div>
</template>

<script>
import player from '@/components/player.vue'
import myhead from '@/components/head.vue'
import foot from '@/components/foot.vue'
import right from '@/components/right.vue'
export default {
  name: "solution",
  data () {
    return {
      state: "",
      videoUrl: "",
      slide: 0,
      sliding: null,
      timer: null,
      swiperOption: {
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        slidesPerView: 4,
        spaceBetween: 20,
        // autoplay: 2000,
        // speed: 300,
      },
      screenWidth: document.body.clientWidth, // 屏幕尺寸
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
      num: ""
    }
  },
  components: {
    foot,
    right,
    myhead,
    player
  },
  mounted () {
    this.onResize()
    window.addEventListener("resize", () => {
      this.screenWidth = document.body.clientWidth
      this.onResize()
    })
    this.peopleNum()
  },
  destroyed () {
    window.removeEventListener("resize", this.onResize)
  },
  computed: {
    isPhone () {
      if (this.screenWidth < 576) {
        return true;
      } else {
        return false;
      }

    },

  },
  methods: {
    onResize () {
      if (this.screenWidth < 576) {
        this.visible1 = false
        this.visible2 = false
        this.visible3 = false
        this.visible4 = false
        this.visible5 = false
        this.visible6 = false
      } else {
        this.visible1 = true
        this.visible2 = true
        this.visible3 = true
        this.visible4 = true
        this.visible5 = true
        this.visible6 = true
      }
    },
    toTry () {
      this.$router.push({ name: "Try" })
    },
    show () {
      // this.$bvModal.show("play")
      // this.videoUrl = "http://celiang.oss-cn-hangzhou.aliyuncs.com/measurement_video/2020-08/24/vnjE6phpMg2YZr1598232727200824.mp4"


      // setTimeout(() => {
      //   this.$refs.play.myplay()
      // }, 10)
    },
    onSlideStart () {
      this.sliding = true
    },
    onSlideEnd () {
      this.sliding = false
    },
    console (num) {
      console.log(num)
    },
    peopleNum () {
      let params = {
        for_count: 1
      }
      this.$axios.get(`${this.$Tools.Conts.domain}official/trials`, { params }).then(res => {
        if (res.data.code === 0) {
          this.num = res.data.data
        } else {
          this.$toast.warn({
            message: res.data.message,
            timeOut: 2000
          })
        }
      })
    }



  }
}
</script>

<style lang="less" scoped>
.part1 {
  padding-top: 8rem;
  padding-bottom: 5rem;
  background: url("../../assets/img/solution/top_bac.png") no-repeat;
  background-size: cover;
  .row {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 1rem 1rem 1.2rem 1rem;
    border-radius: 0.5rem;
    margin: auto;
    .text2 {
      font-size: 1.5rem;
      padding-top: 0.3rem;
      padding-bottom: 0.8rem;
    }
    .text3 {
      font-size: 0.8rem;
      padding-bottom: 0.8rem;
    }
    .btn {
      font-size: 0.7rem;
      padding: 0.6rem 2rem;
    }
  }
}
.part2 {
  padding: 2rem 0 1rem;
  .part2_title {
    padding: 0 0 1.5rem;
    font-size: 1.2rem;
  }
  .part2_content {
    width: 90%;
    margin: auto;
    text-indent: 2rem;
    font-size: 0.8rem;
  }
  .hard {
    font-size: 1.2rem;
    color: #2b85e4;
    text-align: center;
    padding: 3rem 0 2rem;
  }
  .hard_content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .item {
      flex: 20%;
      text-align: center;
      padding-bottom: 1rem;
      color: #858585;
      font-size: 0.7rem;
      img {
        width: 2rem;
        height: 2rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
.part3 {
  padding: 2rem 0;
  background: url("../../assets/img/solution/bac.png") no-repeat;
  background-size: cover;
  color: white;
  .part3_title {
    padding: 0 0 1.5rem;
    font-size: 1.2rem;
  }
  .part3_content {
    width: 90%;
    margin: auto;
    text-indent: 2rem;
    font-size: 0.7rem;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 2rem auto 0;
    .item {
      background: rgba(43, 133, 228, 0.25);
      display: flex;
      padding: 1rem;
      width: 100%;
      margin-bottom: 1rem;
      .item_left {
        margin-right: 1rem;
        img {
          width: 3rem;
          height: 3rem;
        }
      }
      .item_right {
        width: 100%;
        .item_title_fa {
          outline: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          &.collapsed /deep/ .b-icon {
            transform: rotate(180deg);
          }
          /deep/ .b-icon {
            display: block;
            height: 16px;
            width: 16px;
            position: absolute;
            right: 10px;
            top: auto;
            transition: transform 0.2s ease-out;
          }
        }
        .item_title {
          font-size: 1.1rem;
          margin-bottom: 0.8rem;
          padding-top: 0.5rem;
        }
        .item_content {
          color: #c1c1c1;
          font-size: 0.8rem;
        }
        .btn {
          font-size: 0.8rem;
          padding: 0.5rem 1.5rem;
          margin: 1rem 0;
        }
      }
    }
  }
}
.part4 {
  .part4_title {
    padding: 3rem 0;
    font-size: 1.2rem;
  }
  .img {
    display: none;
  }
  .img1 {
    display: block;
  }
  img {
    width: 100%;
  }
}
.part6 {
  .part6_title {
    padding: 3rem 0;
    font-size: 1.2rem;
  }
  .brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 850px;
    margin: auto;
    img {
      width: 160px;
      border: 1px solid #c1c1c1;
      margin-bottom: 0.5rem;
    }
  }
  .btn {
    padding: 0.5rem 3rem;
    margin: 2rem auto;
    display: block;
    box-shadow: 0px 1px 4px #007bff;
    .btn_text1 {
      font-size: 0.9rem;
    }
    .btn_text2 {
      font-size: 0.7rem;
      opacity: 0.7;
    }
  }
}
@media screen and (min-width: 576px) {
  .part1 {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 10rem;
    padding-right: 10rem;
    background: url("../../assets/img/solution/top_bac.png") no-repeat;
    background-size: cover;
    .row {
      width: 55%;
      margin: initial;
      .text2 {
        font-size: 1.6rem;
        padding-top: 0rem;
        padding-bottom: 0.8rem;
      }
      .text3 {
        font-size: 0.8rem;
        padding-bottom: 0.8rem;
      }
      .btn {
        font-size: 0.8rem;
        padding: 0.6rem 2rem;
      }
    }
  }

  .part2 {
    padding: 3rem 10rem;
    .part2_title {
      padding: 0 0 1.5rem;
      font-size: 2rem;
    }
    .part2_content {
      width: 80%;
      margin: auto;
      text-indent: 2rem;
      font-size: 1rem;
    }
    .hard {
      font-size: 1.2rem;
      color: #2b85e4;
      text-align: center;
      padding: 3rem 0 3rem;
    }
    .hard_content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 95%;
      margin: auto;
      .item {
        flex: 20%;
        text-align: center;
        padding-bottom: 1.5rem;
        color: #858585;
        font-size: 1rem;
        img {
          width: 3rem;
          height: 3rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .part3 {
    padding: 3rem 10rem;
    background: url("../../assets/img/solution/bac.png") no-repeat;
    background-size: cover;
    color: white;
    .part3_title {
      padding: 0 0 1.5rem;

      font-size: 2rem;
    }
    .part3_content {
      width: 80%;
      margin: auto;
      text-indent: 2rem;
      font-size: 1rem;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 2rem;
      width: 100%;
      .item {
        background: rgba(43, 133, 228, 0.25);
        display: flex;
        padding: 2rem 3rem 1rem 3rem;
        width: calc(50% - 0.5rem);
        margin-bottom: 1rem;
        .item_left {
          margin-right: 1rem;
          img {
            width: 60px;
            height: 60px;
          }
        }
        .item_right {
          width: 100%;
          .item_title_fa {
            /deep/ .b-icon {
              display: none;
            }
          }
          .item_title {
            font-size: 1.2rem;
            margin-bottom: 0.8rem;
            padding-top: 0;
          }
          .item_content {
            color: #c1c1c1;
            font-size: 1rem;
          }
          .btn {
            font-size: 0.8rem;
            padding: 0.7rem 2rem;
            margin: 1rem 0;
          }
        }
      }
    }
  }
  .part4 {
    padding: 0 10rem;
    .part4_title {
      padding: 5rem 0 3rem;
      font-size: 2rem;
    }
    .img {
      display: block;
    }
    .img1 {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .part6 {
    .part6_title {
      padding: 5rem 0 3rem;
      font-size: 2rem;
    }
    .part6_futitle {
      font-size: 1rem;
      padding-bottom: 3rem;
      color: gray;
    }
    .brand {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      max-width: 850px;
      margin: auto;
      img {
        width: 160px;
        border: 1px solid #c1c1c1;
        margin-bottom: 0.5rem;
      }
    }
    .btn {
      padding: 0.5rem 3rem;
      margin: 2rem auto;
      display: block;
      box-shadow: 0px 1px 4px #007bff;
      .btn_text1 {
        font-size: 0.9rem;
      }
      .btn_text2 {
        font-size: 0.7rem;
        opacity: 0.7;
      }
    }
  }
}
</style>